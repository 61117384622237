$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_spacing';
@import '../../../scss/layout/_variables';

.wrap {
    margin-top: $sassy-spacing-small;
}

.iconWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: $favorites-icon-size;
    min-height: $favorites-icon-size;
    margin-top: $sassy-spacing-x-small;
    position: relative;
    margin-left: auto;
}

.icon {
    padding: 1px;
    color: transparent;
    stroke-width: 12px;
    stroke: $sassy-color-buyer-primary;
}

.heartButton {
    width: 100%;
}
